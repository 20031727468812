import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第一輪</h2>
          <h1 className="main_title">#傳承創新</h1>
        </div>


      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_7">
            <figure className="figure">
              <img src={require("../../images/blog/post_2_1_2.jpg")} alt=""/>
              <figcaption className="figcaption left">組長：孫啟榕<br/>組員：吳思璇、陳青琳、黃金樺</figcaption>
            </figure>
          </div>
        </section>
        <section className="row_area_s">
          <div className="column_5">
            <div className="context">
              <p>第一個想法是<b>「教育」</b>，學校和周邊社區連線，以公共藝術的思維結合教育，給予社區或學校傳承的可能性。</p>
              <p>第二是<b>「藝術家進駐」</b>，藝術家進入社宅居住、生活，與在地居民互動，可與在地產生較深的連結並形成更深入在地的創作。</p>
              <p>第三，<b>「中介組織」</b>管理機制，在藝術家交替、居民流動的狀況下，中介組織可作為一輪輪遷徙群體之間連結、傳承的機制可能。他們可以累積在地文化內容、社區職人或長期居住者的故事、提供藝術家與在地的連結，不斷累積再傳給下一位藝術家，每次藝術家提出來的內容都成為Data，讓新住民認識社區的機會變更高，他們思考藝術家如何跟在地關係連結，一直傳承下去。</p>
              <p className="onset_right_1">最後，思考藝術家或在地居民離開後的<b>「退場機制」</b>，以傳承在地文化。可以由中介組織保留更多文史資料或藝術家提案。</p>
              <p className="onset_right_1">舉例來說，由藝術家設計社區導覽路線，可能產出地圖，或實際導覽，每年不斷累積，這樣的在地地圖或許成為公共藝術作品，傳承地方文化內容。</p>
              <p className="onset_right_1">另外，舉台南市政府文化局為例，他們推出由明星級歷史人物鄭成功代言的「成功洋芋片」為台南重要國定古蹟進行另類行銷。或許藝術家可以是產品包裝設計師，為社區型產品重新包裝，開發出新的可能性。 </p>
            </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_2_2" className="next_page">
            <div className="next_title">#公民社會</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第一輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
